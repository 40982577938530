import React from "react";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Container, Row } from "react-bootstrap";

const ServiceBox = ({ title, image, uri, height, span, mdSpan, minHeight }) => {
	const medSpan = mdSpan || 6;
	const minHt = minHeight || "";
	return (
		<Col style={{ minHeight: "100%" }} md={medSpan} lg={span}>
			<Link className="white-link-green" to={uri}>
				<BgImage
					style={{ height: height, minHeight: minHt }}
					className="w-100"
					image={getImage(image)}
				>
					<Container className="h-100">
						<Row className="align-items-end h-100">
							<Col
								className="py-2 "
								style={{ background: "rgba(0, 0, 0,0.4)" }}
								xs={12}
							>
								<h3 className="white-link-green fs-4 mb-0 pb-0">{title}</h3>
							</Col>
						</Row>
					</Container>
				</BgImage>
			</Link>
		</Col>
	);
};

export default ServiceBox;
