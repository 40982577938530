import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";

const ImageSide = ({
	image,
	title,
	text,
	svg,
	imageHeight,
	imageAlt,
	order,
	mobileImageHeight,
	bgColour,
}) => {
	const height = imageHeight || "";
	const mobileHeight = mobileImageHeight || "";
	return (
		<section className={`py-5 py-lg-7  position-relative ${bgColour}`}>
			<Container className="">
				<Row className="align-items-center g-5 g-lg-6">
					<Col lg={{ span: 6, order: order }}>
						<GatsbyImage
							style={{ height: height }}
							className="position-relative w-100 d-none d-lg-block"
							image={image}
							alt={imageAlt}
						/>
						<GatsbyImage
							style={{ height: "30rem" }}
							className="position-relative w-100 d-none d-md-block d-lg-none"
							image={image}
							alt={imageAlt}
						/>
						<GatsbyImage
							style={{ height: mobileHeight }}
							className="position-relative w-100 d-md-none"
							image={image}
							alt={imageAlt}
						/>
					</Col>
					<Col lg={{ span: 6 }}>
						<div className="d-inline-block position-relative">
							{svg}
							<h2
								style={{ zIndex: 2 }}
								className="d-inline-block position-relative mb-4 text-primary"
							>
								{title}
							</h2>
						</div>
						{text}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ImageSide;
