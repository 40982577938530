import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Link, useStaticQuery, graphql } from "gatsby";
import gsap from "gsap";

const ServiceHero = ({ title, description, backgroundImage, ctaButton, image }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: wpMediaItem(title: { eq: "Tops-green" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);
  const pluginImage = getImage(backgroundImage);
  useEffect(() => {
    gsap.to(".heading-fade", {
      opacity: 1,
      duration: 1,
      delay: 0.5,
      transform: "scale(1)",
    });
    gsap.to(".text-fade", {
      opacity: 1,
      duration: 1,
      delay: 0.8,
      transform: "scale(1)",
    });
  }, []); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED
  return (
    <BgImage className=" " image={pluginImage}>
      <Container className="py-5 pt-lg-6 pb-lg-10">
        <Row
          className="justify-content-center
                "
        >
          <Col lg={8} className="">
            <div
              className="w-100 pt-5 pb-7 text-center px-5 position-relative"
              style={{ background: "rgba(255, 255, 255,0.82)" }}
            >
              <GatsbyImage
                style={{ maxWidth: "70px" }}
                image={image?.localFile.childImageSharp.gatsbyImageData}
                alt={image?.altText}
              />
              <h1
                style={{ opacity: 0, transform: "scale(0.95)" }}
                className=" mb-3 mt-3 fs-2 heading-fade text-primary"
              >
                {title}
              </h1>
              <p
                style={{
                  fontSize: "100%",
                  opacity: 0,
                  transform: "scale(0.95)",
                }}
                className="mb-0 px-lg-5 text-black text-fade hero-content"
              >
                {description}
              </p>
              <div
                className="w-100 bg-secondary position-absolute d-none d-md-block start-0 bottom-0"
                style={{ height: "25px" }}
              ></div>
              <div
                className="w-100  position-absolute start-0  "
                style={{ bottom: "-0.5rem" }}
              >
                <div className="position-relative w-100 py-2">
                  {ctaButton && ctaButton.url && (
                    <Link
                      style={{ zIndex: 3 }}
                      className="secondary-link-white quicksand position-relative"
                      to={ctaButton?.url}
                    >
                      {ctaButton?.title}
                    </Link>
                  )}

                  <div
                    style={{ zIndex: 0 }}
                    className="bg-primary w-100 w-md-70 position-absolute top-0 start-0 h-100"
                  >
                    {" "}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </BgImage>
  );
};

export default ServiceHero;
