import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const OpeningBanner = ({ heading }) => {
	const data = useStaticQuery(graphql`
		query {
			wpOpeningTime(title: { eq: "Default Opening" }) {
				title
				openingTimeFields {
					mondaySaturday
					sunday
				}
			}
		}
	`);

	return (
		<section
			style={{ overflow: "hidden" }}
			className={`bg-primary position-relative py-5`}
		>
			<StaticImage
				className="position-absolute "
				style={{ zIndex: 1, bottom: "-9rem", right: "-9rem" }}
				quality="100"
				src="../images/right-leaf.svg"
				placeholder="blurred"
				width={400}
				formats={["auto", "webp"]}
        alt="leaf"
			/>
			<Container style={{ zIndex: 2 }} className="position-relative">
				<Row className="">
					<Col className="text-center">
						<h2 className="text-secondary mb-4">{heading ?? "OPENING HOURS"}</h2>
						<p className="text-white fs-3 py-0 m-0">
							<span className="quicksand">Monday - Saturday</span>{" "}
							<br className="d-md-none" />
							{data.wpOpeningTime.openingTimeFields.mondaySaturday}{" "}
							<br className="d-lg-none" />
							<span className="quicksand ps-lg-5">Sunday</span>{" "}
							<br className="d-md-none" />
							{data.wpOpeningTime.openingTimeFields.sunday}
						</p>{" "}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default OpeningBanner;
