import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceBox from "../components/service-box";
import ShowroomSection from "../components/showroom-section";
import ServiceHero from "../components/service-hero";
import ImageSide from "../components/image-side";
import OpeningBanner from "../components/opening-banner";
import Reviews from "../components/reviews";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-ntml-parser";

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "services" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        servicesPageFieldGroups {
          servicesPageBannerSection {
            heading
            description
            cta1 {
              target
              url
              title
            }
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          servicesPageTextSection {
            heading
            description
          }
          servicesPageTwoColumnSection1 {
            heading
            description
            cta {
              target
              title
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          servicesPageTwoColumnSection2 {
            heading
            description
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          servicesPageReFeltingSection {
            heading
            description
            afterImage {
              heading
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
            beforeImage {
              heading
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          servicesPageTwoColumnSection3 {
            heading
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          servicesPageTwoColumnSection4 {
            heading
            description
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          servicesPageServiceSection {
            heading
            conservatories {
              heading {
                title
                target
                url
              }
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
            garages {
              heading {
                target
                title
                url
              }
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
            summerHouses {
              heading {
                target
                title
                url
              }
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          servicesPageReviewsSection {
            heading
          }
          servicesPageOpeningHoursSection {
            heading
          }
          servicesPageCtaSection1 {
            heading
            description
            cta {
              title
              target
              url
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, servicesPageFieldGroups },
  } = data;

  const {
    servicesPageBannerSection,
    servicesPageTextSection,
    servicesPageTwoColumnSection1,
    servicesPageTwoColumnSection2,
    servicesPageReFeltingSection,
    servicesPageTwoColumnSection3,
    servicesPageTwoColumnSection4,
    servicesPageServiceSection,
    servicesPageReviewsSection,
    servicesPageOpeningHoursSection,
    servicesPageCtaSection1,
  } = servicesPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Services",
        item: {
          url: `${siteUrl}/services`,
          id: `${siteUrl}/services`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/services`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {servicesPageBannerSection &&
            !checkPropertiesForNull(servicesPageBannerSection, ["heading"]) && (
              <ServiceHero
                backgroundImage={
                  servicesPageBannerSection.backgroundImage.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                title={servicesPageBannerSection?.heading}
                description={
                  <SafeHtmlParser
                    htmlContent={servicesPageBannerSection?.description}
                  />
                }
                ctaButton={servicesPageBannerSection?.cta1}
                image={servicesPageBannerSection?.image.node}
              />
            )}

          {servicesPageTextSection &&
            !checkPropertiesForNull(servicesPageTextSection, ["heading"]) && (
              <section className="py-5 py-lg-7">
                <Container>
                  <Row>
                    <Col lg={4}>
                      <h2 className=" fs-1 mb-4 text-primary  ">
                        <SafeHtmlParser
                          htmlContent={servicesPageTextSection?.heading}
                        />
                      </h2>
                    </Col>
                    <Col lg={8}>
                      <SafeHtmlParser
                        htmlContent={servicesPageTextSection?.description}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {servicesPageTwoColumnSection1 &&
            !checkPropertiesForNull(servicesPageTwoColumnSection1, [
              "heading",
            ]) && (
              <ImageSide
                mobileImageHeight="20rem"
                svg={
                  <StaticImage
                    className="position-absolute d-none d-lg-block"
                    style={{ zIndex: 1, top: "-4rem", right: "-4rem" }}
                    quality="100"
                    src="../images/single-leaf.svg"
                    placeholder="blurred"
                    width={130}
                    formats={["auto", "webp"]}
                  />
                }
                svgPos="1rem"
                title={servicesPageTwoColumnSection1?.heading}
                text={
                  <span>
                    <SafeHtmlParser
                      htmlContent={servicesPageTwoColumnSection1?.description}
                    />
                    {servicesPageTwoColumnSection1.cta &&
                      servicesPageTwoColumnSection1.cta.url && (
                        <Button
                          className="mt-4 w-100 w-md-auto"
                          as={Link}
                          to={servicesPageTwoColumnSection1.cta?.url}
                          variant="primary"
                          target={
                            servicesPageTwoColumnSection1.cta?.target ??
                            "_blank"
                          }
                        >
                          {servicesPageTwoColumnSection1.cta?.title}
                        </Button>
                      )}
                  </span>
                }
                imageHeight="25rem"
                image={
                  servicesPageTwoColumnSection1.image.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imageAlt={servicesPageTwoColumnSection1.image.node?.altText}
              />
            )}
          {servicesPageTwoColumnSection2 &&
            !checkPropertiesForNull(servicesPageTwoColumnSection2, [
              "heading",
            ]) && (
              <section className="pb-5 pb-lg-7">
                <div className="position-relative d-none d-xl-block">
                  <GatsbyImage
                    style={{ zIndex: 2 }}
                    className="w-100 position-relative "
                    image={
                      servicesPageTwoColumnSection2.backgroundImage.node
                        ?.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={
                      servicesPageTwoColumnSection2.backgroundImage.node
                        ?.altText
                    }
                  />
                  <Container
                    style={{ zIndex: 3 }}
                    className="position-absolute pb-5 top-50 start-50 translate-middle"
                  >
                    <Row className="align-items-center pb-4 g-5">
                      <Col lg={{ span: 6, order: "last" }}>
                        <GatsbyImage
                          className="w-100 "
                          image={
                            servicesPageTwoColumnSection2.image.node?.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={
                            servicesPageTwoColumnSection2.image.node?.altText
                          }
                        />
                      </Col>
                      <Col className="text-white" lg={6}>
                        <h2 className="mb-4">
                          {servicesPageTwoColumnSection2?.heading}
                        </h2>
                        <SafeHtmlParser
                          htmlContent={
                            servicesPageTwoColumnSection2?.description
                          }
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
                <BgImage
                  className=" w-100 py-5 d-xl-none pb-lg-8 "
                  image={getImage(
                    servicesPageTwoColumnSection2.backgroundImage.node
                      ?.localFile.childImageSharp.gatsbyImageData
                  )}
                >
                  <Container>
                    <Row className="align-items-center pb-4 g-5">
                      <Col lg={{ span: 6, order: "last" }}>
                        <GatsbyImage
                          className="w-100 "
                          image={
                            servicesPageTwoColumnSection2.image.node?.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={
                            servicesPageTwoColumnSection2.image.node?.altText
                          }
                        />
                      </Col>
                      <Col className="text-white" lg={6}>
                        <h2 className="mb-4">
                          {servicesPageTwoColumnSection2?.heading}
                        </h2>
                        <SafeHtmlParser
                          htmlContent={
                            servicesPageTwoColumnSection2?.description
                          }
                        />
                      </Col>
                    </Row>
                  </Container>
                </BgImage>
              </section>
            )}
          {servicesPageReFeltingSection &&
            !checkPropertiesForNull(servicesPageReFeltingSection, [
              "heading",
            ]) && (
              <section className="pb-5 pt-5  pb-lg-7">
                <Container>
                  <Row>
                    <Col lg={6} className="">
                      <div className="d-inline-block position-relative">
                        <h2
                          style={{ zIndex: 2 }}
                          className=" mb-4 text-primary d-inline-block fs-1 text-center position-relative "
                        >
                          {servicesPageReFeltingSection?.heading}
                        </h2>
                        <StaticImage
                          className="position-absolute d-none d-lg-block"
                          style={{ zIndex: 1, top: "-4rem", right: "-4rem" }}
                          quality="100"
                          src="../images/single-leaf.svg"
                          placeholder="blurred"
                          width={130}
                          formats={["auto", "webp"]}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <SafeHtmlParser
                        htmlContent={servicesPageReFeltingSection?.description}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          <section className="pb-5 pb-lg-7">
            <Container>
              <Row className="g-5">
                {servicesPageReFeltingSection.beforeImage && (
                  <Col md={6}>
                    <BgImage
                      style={{ height: "30rem" }}
                      className="w-100"
                      image={getImage(
                        servicesPageReFeltingSection.beforeImage.image.node
                          ?.localFile.childImageSharp.gatsbyImageData
                      )}
                    >
                      <Container className="h-100">
                        <Row className="align-items-end h-100">
                          <Col
                            className="py-2 "
                            style={{ background: "rgba(0, 0, 0,0.4)" }}
                            xs={12}
                          >
                            <h3 className="white-link fs-4 mb-0 pb-0">
                              {
                                servicesPageReFeltingSection.beforeImage
                                  ?.heading
                              }
                            </h3>
                          </Col>
                        </Row>
                      </Container>
                    </BgImage>
                  </Col>
                )}
                {servicesPageReFeltingSection.afterImage && (
                  <Col md={6}>
                    <BgImage
                      style={{ height: "30rem" }}
                      className="w-100"
                      image={getImage(
                        servicesPageReFeltingSection.afterImage.image.node
                          ?.localFile.childImageSharp.gatsbyImageData
                      )}
                    >
                      <Container className="h-100">
                        <Row className="align-items-end h-100">
                          <Col
                            className="py-2 "
                            style={{ background: "rgba(0, 0, 0,0.4)" }}
                            xs={12}
                          >
                            <h3 className="white-link fs-4 mb-0 pb-0">
                              {servicesPageReFeltingSection.afterImage?.heading}
                            </h3>
                          </Col>
                        </Row>
                      </Container>
                    </BgImage>
                  </Col>
                )}
              </Row>
            </Container>
          </section>

          {servicesPageTwoColumnSection3 &&
            !checkPropertiesForNull(servicesPageTwoColumnSection3, [
              "heading",
            ]) && (
              <ImageSide
                bgColour="bg-light-grey"
                mobileImageHeight="20rem"
                svg={
                  <StaticImage
                    className="position-absolute d-none d-lg-block"
                    style={{ zIndex: 1, top: "-4rem", right: "-4rem" }}
                    quality="100"
                    src="../images/single-leaf.svg"
                    placeholder="blurred"
                    width={130}
                    formats={["auto", "webp"]}
                  />
                }
                svgPos="1rem"
                title={servicesPageTwoColumnSection3?.heading}
                text={
                  <span>
                    <SafeHtmlParser
                      htmlContent={servicesPageTwoColumnSection3?.description}
                    />
                    {servicesPageTwoColumnSection3.cta &&
                      servicesPageTwoColumnSection3.cta.url && (
                        <Button
                          className="mt-4 w-100 w-md-auto"
                          as={Link}
                          to={servicesPageTwoColumnSection3.cta?.url}
                          variant="primary"
                          target={
                            servicesPageTwoColumnSection3.cta?.target ??
                            "_blank"
                          }
                        >
                          {servicesPageTwoColumnSection3.cta?.title}
                        </Button>
                      )}
                  </span>
                }
                imageHeight="25rem"
                image={
                  servicesPageTwoColumnSection3.image.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imageAlt={servicesPageTwoColumnSection3.image.node?.altText}
              />
            )}
          {servicesPageTwoColumnSection4 &&
            !checkPropertiesForNull(servicesPageTwoColumnSection4, [
              "heading",
            ]) && (
              <ImageSide
                order="last"
                mobileImageHeight="20rem"
                svg={
                  <StaticImage
                    className="position-absolute d-none d-lg-block"
                    style={{ zIndex: 1, top: "-4rem", right: "-4rem" }}
                    quality="100"
                    src="../images/single-leaf.svg"
                    placeholder="blurred"
                    width={130}
                    formats={["auto", "webp"]}
                  />
                }
                svgPos="1rem"
                title={servicesPageTwoColumnSection4?.heading}
                text={
                  <span>
                    <SafeHtmlParser
                      htmlContent={servicesPageTwoColumnSection4?.description}
                    />
                  </span>
                }
                imageHeight="25rem"
                image={
                  servicesPageTwoColumnSection4.image.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imageAlt={servicesPageTwoColumnSection4.image.node?.altText}
              />
            )}
          {servicesPageServiceSection &&
            !checkPropertiesForNull(servicesPageServiceSection, [
              "heading",
            ]) && (
              <section id="introduction" className="pb-5 pb-lg-7 ">
                <Container>
                  <Row>
                    <Col>
                      <h2 className="text-center text-primary mb-5 fs-1">
                        {servicesPageServiceSection?.heading}
                      </h2>
                    </Col>
                  </Row>
                  <Row className="g-3">
                    <Col lg={5}>
                      <Row className="g-3">
                        {servicesPageServiceSection?.garages && (
                          <ServiceBox
                            span={12}
                            height="20rem"
                            uri={
                              servicesPageServiceSection?.garages?.heading?.url
                            }
                            title={
                              servicesPageServiceSection?.garages?.heading
                                ?.title
                            }
                            image={
                              servicesPageServiceSection.garages?.image.node
                                ?.localFile.childImageSharp.gatsbyImageData
                            }
                          />
                        )}
                        {servicesPageServiceSection?.summerHouses && (
                          <ServiceBox
                            span={12}
                            height="20rem"
                            uri={
                              servicesPageServiceSection.summerHouses.heading
                                ?.url
                            }
                            title={
                              servicesPageServiceSection?.summerHouses.heading
                                ?.title
                            }
                            image={
                              servicesPageServiceSection.summerHouses.image
                                .node?.localFile.childImageSharp.gatsbyImageData
                            }
                          />
                        )}
                      </Row>
                    </Col>
                    <Col style={{ minHeight: "100%" }} lg={7}>
                      <Row className="h-100">
                        {servicesPageServiceSection?.conservatories && (
                          <ServiceBox
                            mdSpan={12}
                            minHeight="20rem"
                            span={12}
                            height="100%"
                            uri={
                              servicesPageServiceSection.conservatories.heading
                                ?.url
                            }
                            title={
                              servicesPageServiceSection?.conservatories.heading
                                ?.title
                            }
                            image={
                              servicesPageServiceSection.conservatories.image
                                .node?.localFile.childImageSharp.gatsbyImageData
                            }
                          />
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          <Reviews
            topPadding="pt-7"
            title={servicesPageReviewsSection?.heading}
          />

          <OpeningBanner heading={servicesPageOpeningHoursSection?.heading} />

          <ShowroomSection
            title={servicesPageCtaSection1?.heading}
            description={
              <SafeHtmlParser
                htmlContent={servicesPageCtaSection1?.description}
              />
            }
            ctaButton={servicesPageCtaSection1?.cta}
          />
        </Layout>
      </div>
    </>
  );
};

export default ServicesPage;
